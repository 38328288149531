<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Verify Doc" icon="check-double" />

    <div class="generalBorderlessBox">
        <div class="row mb-5">
            <div class="col-lg-5 d-grid">
                <div class="generalContentBox border shadow-sm mb-0">
                    <table class="table table-borderless table-sm" border="0" height="150px">
                        <tr>
                            <td  class="align-top">Key in the Document ID or Folder ID</td>
                            <td class="text-end pt-0 ps-2">
                                <fa icon="file-pdf" style="font-size: 120px" />
                            </td>
                        </tr>
                    </table>
                    <div>
                        <div class="input-group">
                            <input type="text" v-model="docId" class="form-control" placeholder="Search Folder ID / Document ID"/>
                            <button class="btn btn-secondary" type="button" id="button-addon2" @click="searchDocById"><fa icon="search" /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 d-grid text-center d-none d-lg-block" style="padding-top: 100px">or</div>
            <div class="col-12 d-grid text-center d-lg-none my-3" >or</div>
            <div class="col-lg-5 d-grid">
                <div class="generalContentBox border shadow-sm mb-0">
                    <div v-if="showQRScan === true" class="center stream mb-3">
                        <qr-stream @decode="onDecode" :camera="auto" class="mb">
                            <div style="color: grey;" class="frame"></div>
                        </qr-stream>
                    </div>
                    <table v-else class="table table-borderless table-sm" border="0" height="150px">
                        <tr>
                            <td class="align-top">Scan QR code from the last page of your document</td>
                            <td class="text-end pt-0 ps-2">
                                <fa icon="qrcode" style="font-size: 120px" />
                            </td>
                        </tr>
                    </table>
                    <div v-if="curQr !== '' && curQr !== null && curQr !== undefined" class="small pb-2">
                        <a :href="curQr" target="_blank">{{curQr}}</a>
                    </div>
                    <div class="d-grid">
                        <button class="btn btn-secondary btn-block" type="button" @click="showQRScan = true">Click to scan QR</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-12 fw-bold mb-0 mt-2">Introduction</div>
            <div class="col-12 mb-3"><hr /></div>
            <div class="col-lg-3 mb-3">
                <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                    <div class="step-number bg-gray-1"><div class="label">1</div></div>
                </div>
                <div class="imgBox"><img alt="Screenshot" src="../../assets/verify_1.jpeg" class="img-fluid" style="height: 154px"></div>
                <div class="pt-2 px-1">
                    Key in or scan QR to retrieve document.
                </div>
            </div>
            <div class="col-lg-3 mb-3">
                <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                    <div class="step-number bg-gray-1"><div class="label">2</div></div>
                </div>
                <div class="imgBox p-1"><img alt="Screenshot" src="../../assets/verify_2.png" class="img-fluid" style="height: 145px"></div>
                <div class="pt-2 px-1">
                    View signing status.
                </div>
            </div>
            <div class="col-lg-3 mb-3">
                <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                    <div class="step-number bg-gray-1"><div class="label">3</div></div>
                </div>
                <div class="imgBox"><img alt="Screenshot" src="../../assets/verify_3.jpg" class="img-fluid" style="height: 154px"></div>
                <div class="pt-2 px-1">
                    Secure sign digitally.
                </div>
            </div>
            <div class="col-lg-3 mb-3">
                <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                    <div class="step-number bg-gray-1"><div class="label">4</div></div>
                </div>
                <div class="imgBox text-center bg-white">
                    <fa icon="envelope" style="font-size: 100px; padding-top: 25px" />
                </div>
                <div class="pt-2 px-1">
                    Notify editors and other signees.
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 fw-bold mb-0 mt-2">Extra</div>
            <div class="col-12 mb-3"><hr /></div>
            <div class="col-lg-3 d-none d-lg-block"><br /></div>
            <div class="col-lg-3 mb-3">
                <div class="mb-5">
                    <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                        <div class="step-number bg-gray-1"><div class="label">2.1</div></div>
                    </div>
                    <div class="imgBox"><img alt="Screenshot" src="../../assets/verify_2.1.png" class="img-fluid" style="height: 154px"></div>
                    <div class="pt-2 px-1">Verify sender, signees and organizations.</div>
                </div>
                <div class="mb-4">
                    <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                        <div class="step-number bg-gray-1"><div class="label">2.2</div></div>
                    </div>
                    <div class="imgBox"><img alt="Screenshot" src="../../assets/verify_2.2.png" class="img-fluid" style="height: 154px"></div>
                    <div class="pt-2 px-1">Verify the original source of the document to ensure no alteration.</div>
                </div>
            </div>
            <div class="col-lg-3 mb-4">
                <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                    <div class="step-number bg-gray-1"><div class="label">3.1</div></div>
                </div>
                <div class="imgBox"><img alt="Screenshot" src="../../assets/verify_3.1.png" class="img-fluid" style="height: 154px"></div>
                <div class="pt-2 px-1">Workflow integration and automation.</div>
            </div>
            <div class="col-lg-3">
                <div style="z-index: 1000; position: absolute; margin-top: -5px; margin-left: -5px">
                    <div class="step-number bg-gray-1"><div class="label">4.1</div></div>
                </div>
                <div class="imgBox text-center bg-white">
                    <fa icon="stamp" style="font-size: 100px; padding-top: 25px" />
                </div>
                <div class="pt-2 px-1">Folder is SEALED for safe keeping.</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, reactive, toRefs, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { QrStream } from 'vue3-qr-reader/dist/vue3-qr-reader.common'
import { useStore } from '@/stores/store'

export default {
    name: 'DocVerify',
    components: {
        Alert, TopNavigation, Popper, QrStream
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs

         const { getUserId } = useStore()

        const docId = ref(null)
        // const docId = ref('bH2pZg0PGr7B2GaUG6Iams')
        const isError = ref(false) 
        const showQRScan = ref(false) 
        const showQrDetail = ref(false)
        const curQr = ref('')
        
        const state = reactive({
            data: null
        })

        const onDecode = (data) => {
            state.data = data

            if (data === null || data === '' || data === undefined) {
                showQrDetail.value = false

            } else {
                showQrDetail.value = true
                if (data !== curQr.value) {
                    curQr.value = data

                }

            }

        }

        const searchDocById = async () => {
            if (docId.value === null || docId.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Please key in full document ID.'
                })

            } else {
                const p = {
                    orderBy: [
                        {
                            field: 'jAuditLog',
                            order: 'desc'
                        }
                    ]
                }

                await axios.post( '/signon/signee/document/' + docId.value, p)
                    .then((res) => {
                        if (res.data.status === 1001) {
                            router.push({ path: '/auth/login', query: {e: 'ns'} })

                        } else if (res.data.status === 1) {
                            // old router.push({ path: '/d/' + docId.value, query: { b: 'VerifyDoc' } })
                            router.push({ path: '/d/' + docId.value })

                        } else {

                            func.addLog('docVerify', 'searchDocById', res)
                            if (res && res.data !== null && res.data !== undefined) {

                                if (res.data.message === 'document_not_found' || res.data.message === 'record_not_found') {
                                    // search by folder if doc not found
                                    getProj(docId.value)
                                
                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })

                                }
                            }
                        }

                    })
                    .catch((error) => {
                        func.addLog('docVerify', 'searchDocById - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })

            }

        }

        const getProj = async (id) => {
            try {
                const p = {
                    orderBy: [
                        {
                            field: 'jAuditLog',
                            order: 'desc'
                        }
                    ]
                }
                
                const res = await axios.post('/signon/signee/' + id, p)
                
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    router.push({ path: '/p/' + id })

                } else {
                    func.addLog('docVerify', 'getProj', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        if (res.data.message === 'folder_not_found' || res.data.message === 'record_not_found') {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Record not found'
                            })

                        } else {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                }

            } catch (error) {
                func.addLog('docVerify', 'getProj - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                
            }

        }

        onMounted(() => {
            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })

            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })

                }

            }

        })

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
            
        }

        return { 
            ...toRefs(state), onDecode, showQrDetail, curQr, 
            alert, closeAlert, router, func, docId, searchDocById, isError, 
            showQRScan, getUserId, 
        }
    }
}
</script>
// https://www.vuescript.com/qr-code-reader-vue-3/
// Only working in HTTPS
// npm i --save vue3-qr-reader

// ref: https://gruhn.github.io/vue-qrcode-reader/demos/ScanSameQrcodeMoreThanOnce.html
<style>

.step-number {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 25px;
    height: 25px;
    padding: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    color: #FFFFFF;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 11px;
    background-color: var(--bs-primary);
}

.imgBox {
    height: 156px;
    border: 1px solid var(--accent-color);
    text-align: center;
    border-radius: 10px;
    background-color: #FFFFFF;
}

.stream {
  max-height: 500px;
  max-width: 500px;
  margin: auto;
}

.frame {
  border-style: dashed;
  border-width: 2px;
  border-color: grey;
  height: 120px;
  width: 120px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}

</style>